import React from "react";
import {Col, Container, Row} from "reactstrap";


export const Footer = () => {
    return (
        <footer>
            <Container className={"footer-container"}>
                <Row>
                    <Col sm={9}>
                        {"Copyright SIA Miateks 2020"}
                    </Col>
                    <Col>
                        <a href="mailto:office@miateks.lv">{"office@miateks.lv"}</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
};
