import './bootstrap.min.css';
import "./layout.scss";
import * as React from 'react';
import {Helmet} from "react-helmet";
import {AppNavbar, LanguageDesktop} from "./navigation";
import {Footer} from "./Footer";
import {configurei18n} from "../config/i18nConfig";
import {configureIcons} from "../config/iconConfig";
import {useMediaQuery} from "react-responsive";

// load roboto typeface
require("typeface-roboto");

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
    location: {
        pathname: string
    }
    children: React.ReactNode
}

// configure languages
configurei18n();
// create icon library
configureIcons();


export default function Layout(props: DefaultLayoutProps){
    const {children} = props;

    const isSmall = useMediaQuery({minWidth: "340px"});

    return (
        <main>
            <Helmet
                title="Miateks"
                meta={[
                    { name: 'description', content: 'Miateks - unparalleled staffing solutions and exceptional service' },
                    { name: 'keywords', content: 'staffing, work, recruitment' },
                ]}
                /*link={[
                    { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
                ]}*/
            />
            <AppNavbar/>
            {children}
            <Footer/>
        </main>
    )
}

