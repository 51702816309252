import "./mainConainer.scss";

import React from "react";
import {Container} from "reactstrap";

export interface MainContainerProps {
    className?: string,
    children?: React.ReactNode
}

export const MainContainer = (props: MainContainerProps) => {
    const {className = "", children} = props;

    return (
        <Container className={`main-container ${className}`}>
            {children}
        </Container>
    )
}
