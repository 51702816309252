import React from "react";
import Link from "gatsby-link";
import {
    Button, Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Navbar,
    NavItem,
    Row
} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";


export interface AppNavBarProps {
    className?: string,
    xSmall?: boolean,
}

export function AppNavbar(props: AppNavBarProps) {
    const {className = "", xSmall = false} = props;

    const {t} = useTranslation("nav");
    const isSmall = useMediaQuery({minWidth: "380px"});

    return (
        <Navbar className={className}>
            <Container>
                <div className={"navlinks"}>
                    <NavItem>
                        <Link activeClassName={"active"} className={"navlink"} to={"/"}>{"Miateks"}</Link>
                    </NavItem>
                    <NavItem>
                        <Link activeClassName={"active"} className={"navlink"} to={"/candidates"}>{t('candidates', 'Candidates')}</Link>
                    </NavItem>
                    <NavItem>
                        <Link activeClassName={"active"} className={"navlink"} to={"/employers"}>{t('employers', 'Employers')}</Link>
                    </NavItem>
                </div>
                <div className={"language-selection"}>
                    <LanguageDesktop/>
                    {isSmall && (<LanguageMobile/>)}
                </div>
            </Container>
        </Navbar>
    )
}


export function LanguageDesktop() {
    const {i18n} = useTranslation("nav");

    return (
        <div className={"language-desktop"}>
            <Button outline color={"primary"} className={`language ${i18n.language === "en" ? "selected" : ""}`}
                    onClick={() => i18n.changeLanguage("en")}
            >
                {"EN"}
            </Button>
            <Button outline color={"primary"} className={`language ${i18n.language === "de" ? "selected" : ""}`}
                    onClick={() => i18n.changeLanguage("de")}
            >
                {"DE"}
            </Button>
            <Button outline color={"primary"} className={`language ${i18n.language === "lv" ? "selected" : ""}`}
                    onClick={() => i18n.changeLanguage("lv")}
            >
                {"LV"}
            </Button>
            <Button outline color={"primary"} className={`language ${i18n.language === "rus" ? "selected" : ""}`}
                    onClick={() => i18n.changeLanguage("rus")}
            >
                {"RUS"}
            </Button>
        </div>
    )
}

export function LanguageMobile() {
    const {i18n} = useTranslation("nav");
    const [dropDownOpen, setDropDownOpen] = React.useState<boolean>(false);

    const getLanguage = (code: string)  => {
        if (code.toLowerCase().includes("en")) return "EN";
        if (code.toLowerCase().includes("de")) return "DE";
        if (code.toLowerCase().includes("lv")) return "LV";
        if (code.toLowerCase().includes("ru")) return "RU";
        return "Lang"
    }
    return (
        <Dropdown className={"language-mobile"} isOpen={dropDownOpen} toggle={() => setDropDownOpen(!dropDownOpen)}>
            <DropdownToggle outline className={"language-toggle"} caret>{getLanguage(i18n.language)}</DropdownToggle>
            <DropdownMenu>
                <DropdownItem className={"language"} onClick={() => i18n.changeLanguage("en")}>{"EN"}</DropdownItem>
                <DropdownItem className={"language"} onClick={() => i18n.changeLanguage("de")}>{"DE"}</DropdownItem>
                <DropdownItem className={"language"} onClick={() => i18n.changeLanguage("lv")}>{"LV"}</DropdownItem>
                <DropdownItem className={"language"} onClick={() => i18n.changeLanguage("rus")}>{"RUS"}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}
