import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faArrowDown,
    faBars,
    faCalendarAlt,
    faCircle,
    faClipboardList,
    faCogs,
    faDotCircle,
    faEllipsisH,
    faEllipsisV,
    faExclamation,
    faFileImport, faHandsHelping,
    faHome,
    faSortDown,
    faToolbox,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";

export const configureIcons = () => {
    library.add(
        faEllipsisV,
        faEllipsisH,
        faBars,
        faCalendarAlt,
        faCircle,
        faDotCircle,
        faHome,
        faCogs,
        faToolbox,
        faFileImport,
        faExclamation,
        faArrowDown,
        faSortDown,

        faClipboardList,
        faUserTie,
        faClock,
        faHandsHelping
    )
}
