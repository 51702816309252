import "./articleContainer.scss";
import React from "react";
import {Container} from "reactstrap";

export interface ArticleSectionProps {
    children?: React.ReactNode,
}

export const ArticleContainer = (props: ArticleSectionProps) => {
    const {children} = props;

    return (
        <Container className={`article-container`}>
            {children}
        </Container>
    )
}

