import "./banner.scss";
import React from "react";
import {useMediaQuery} from "react-responsive";
import {LanguageDesktop, LanguageMobile} from "../layouts/navigation";

export interface BannerProps {
    children?: React.ReactNode,
    className?: string,
    background?: string
}

export function Banner(props: BannerProps) {

    const {children, className = "", background = ""} = props;
    const isSmall = useMediaQuery({maxWidth: "380px"});

    return (
        <>
            <div className={`banner ${className}`}>
                <div className={`background ${background}`}>
                    <div className={"banner-contents-container"}>
                        {children}
                    </div>
                </div>
            </div>
            {isSmall && (
                <div className={"language-selection-xs"}>
                    <LanguageDesktop/>
                </div>
            )}
        </>
    )
}
